import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";
import {
    addDays,
    addHours,
    addMinutes,
    addMonths,
    addYears,
    format,
    getDayOfYear,
    isSameMinute,
    setHours,
    setMinutes,
} from "date-fns";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import RRule, { Frequency } from "rrule";
import { RecurrenceHelper } from "./recurrence-helper";
import { TranslocoService } from "@ngneat/transloco";
import { VuiValidators } from "../directives/mat-input-required.directive";
import { createTimeMask } from "../date-form-field/model";
import { set } from "lodash";

export const FREQ_MINUTELY = Frequency.MINUTELY;
export const FREQ_HOURLY = Frequency.HOURLY;
export const FREQ_DAILY = Frequency.DAILY;
export const FREQ_WEEKLY = Frequency.WEEKLY;
export const FREQ_MONTHLY = Frequency.MONTHLY;
export const FREQ_YEARLY = Frequency.YEARLY;

export const MILLISECONDS_IN_A_YEAR = 31536000000;
export const MILLISECONDS_IN_A_MONTH = 2592000000;
export const MILLISECONDS_IN_A_WEEK = 604800000;
export const MILLISECONDS_IN_A_DAY = 86400000;
export const MILLISECONDS_IN_AN_HOUR = 3600000;
export const MILLISECONDS_IN_A_MINUTE = 60000;

@Component({
    selector: "recurrence-dialog",
    templateUrl: "./recurrence-picker-dialog.component.html",
    styleUrls: ["./recurrence-picker-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurrencePickerDialogComponent implements OnInit, OnDestroy {
    _unsubscribeAll: Subject<any>;

    allowPickDayOfWeek: boolean;

    repeatOptions: any[] = [
        {
            day: RRule.SU,
            text: this._translationService.translate("vui.recurrence-picker.SU"),
            checked: false,
        },
        {
            day: RRule.MO,
            text: this._translationService.translate("vui.recurrence-picker.MO"),
            checked: false,
        },
        {
            day: RRule.TU,
            text: this._translationService.translate("vui.recurrence-picker.TU"),
            checked: false,
        },
        {
            day: RRule.WE,
            text: this._translationService.translate("vui.recurrence-picker.WE"),
            checked: false,
        },
        {
            day: RRule.TH,
            text: this._translationService.translate("vui.recurrence-picker.TH"),
            checked: false,
        },
        {
            day: RRule.FR,
            text: this._translationService.translate("vui.recurrence-picker.FR"),
            checked: false,
        },
        {
            day: RRule.SA,
            text: this._translationService.translate("vui.recurrence-picker.SA"),
            checked: false,
        },
    ];

    frequencyOptions: any[] = [
        {
            id: FREQ_MINUTELY,
            text: this._translationService.translate("vui.recurrence-picker.FREQ.MINUTELY"),
        },
        {
            id: FREQ_HOURLY,
            text: this._translationService.translate("vui.recurrence-picker.FREQ.HOURLY"),
        },
        {
            id: FREQ_DAILY,
            text: this._translationService.translate("vui.recurrence-picker.FREQ.DAILY"),
        },
        {
            id: FREQ_WEEKLY,
            text: this._translationService.translate("vui.recurrence-picker.FREQ.WEEKLY"),
        },
        {
            id: FREQ_MONTHLY,
            text: this._translationService.translate("vui.recurrence-picker.FREQ.MONTHLY"),
        },
        {
            id: FREQ_YEARLY,
            text: this._translationService.translate("vui.recurrence-picker.FREQ.YEARLY"),
        },
    ];

    form: FormGroup;

    timeMask = createTimeMask();

    recurrenceText: string | undefined;

    allowPickStartDate: boolean;

    availableHours = Array.from({ length: 24 }, (_, i) => i);

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<RecurrencePickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _translationService: TranslocoService
    ) {
        this._unsubscribeAll = new Subject();

        this.form = this._formBuilder.group({
            start: [null, [VuiValidators.required]],
            dtstart: [null, [VuiValidators.required]],
            interval: [1, [Validators.required, Validators.min(1)]],
            freq: [Frequency.WEEKLY, Validators.required],
            byweekday: [null, Validators.required],
            bymonthday: [null, Validators.required],
            byyearday: [null, Validators.required],
            finishType: ["never", Validators.required],
            until: [{ value: null, disabled: true }, [Validators.required, this.afterStartDateValidator()]],
            count: [{ value: 1, disabled: true }, [Validators.required, Validators.min(1)]],
            endInterval: [7],
            endFreq: [Frequency.DAILY],
            allowByHourDefinition: [false],
            byhour: this._formBuilder.array([], [Validators.minLength(2), this.distinctValuesValidator()]),
        });

        this.dialogRef.keydownEvents().subscribe((event) => {
            if (event.key === "Escape") {
                this.closeDialog();
            }
        });
    }

    ngOnInit(): void {
        this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll), delay(300)).subscribe((value) => {
            let rule = this.parseDefinition();
            this.recurrenceText = RecurrenceHelper.parseRecurrenceMessage(
                rule.toString(),
                this.data.stampStartDate ?? false
            );
            this._changeDetector.markForCheck();
        });

        this.form
            .get("freq")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.allowPickDayOfWeek = value === Frequency.WEEKLY;
                if (this.allowPickDayOfWeek) {
                    this.form.get("byweekday").enable();
                } else {
                    this.form.get("byweekday").disable();
                }

                switch (value) {
                    case Frequency.WEEKLY:
                        this.form.get("bymonthday").disable();
                        this.form.get("byyearday").disable();
                        break;
                    case Frequency.MONTHLY:
                        this.form.get("bymonthday").enable();
                        this.form.get("byyearday").disable();
                        break;
                    case Frequency.YEARLY:
                        this.form.get("bymonthday").disable();
                        this.form.get("byyearday").enable();
                        break;
                }
            });

        this.form
            .get("allowByHourDefinition")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                if (value) {
                    this.form.get("byhour").enable();
                    this.form.get("freq").setValue(Frequency.WEEKLY);
                    this.form.get("interval").setValue(1);
                    this.form.get("freq").disable();
                } else {
                    this.form.get("byhour").disable();
                    this.form.get("freq").enable();
                }
                this._changeDetector.markForCheck();
            });

        this.form
            .get("finishType")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                switch (value) {
                    case "endDate":
                        this.untilState(false);
                        this.countState(false);
                        this.endDateState(true);
                        break;
                    case "never":
                        this.untilState(false);
                        this.countState(false);
                        this.endDateState(false);
                        break;
                    case "until":
                        this.untilState(true);
                        this.countState(false);
                        this.endDateState(false);
                        break;
                    case "count":
                        this.untilState(false);
                        this.countState(true);
                        this.endDateState(false);
                        break;
                }

                this._changeDetector.markForCheck();
            });

        let date: Date = this.data?.date ?? new Date();
        let rule = RRule.fromString(this.data?.recurrence ?? "");

        if (rule.options.freq === Frequency.WEEKLY) {
            rule.options.byweekday.forEach((day) => {
                let item = this.repeatOptions.find((i) => i.day.weekday === day);
                item.checked = true;
            });
        }

        if (rule.options.until) {
            this.untilState(true);
        }

        if (rule.options.count && rule.options.count > 0) {
            this.countState(true);
        }

        this.allowPickDayOfWeek = rule.options.freq === Frequency.WEEKLY;
        if (this.allowPickDayOfWeek) {
            this.form.get("byweekday").enable();
        } else {
            this.form.get("byweekday").disable();
        }

        this.allowPickStartDate = !isSameMinute(rule.options.dtstart, new Date());
        if (!this.allowPickStartDate) {
            this.form.get("dtstart").disable();
            this.form.get("start").disable();
        }

        if (rule.options.byhour && rule.options.byhour.length > 1) {
            this.form.get("allowByHourDefinition").setValue(true);
            for (const hour of rule.options.byhour) {
                this.byHourArray.push(new FormControl(hour, Validators.required));
            }
        }

        this.form.patchValue({
            start: rule.options.dtstart ? format(rule.options.dtstart, "HH:mm") : "08:00",
            dtstart: this.allowPickStartDate ? rule.options.dtstart : null,
            interval: rule.options.interval,
            freq: rule.options.freq,
            byweekday: rule.options.byweekday,
            bymonthday:
                rule.options.bymonthday && rule.options.bymonthday.length > 0
                    ? rule.options.bymonthday
                    : [date.getDate()],
            byyearday:
                rule.options.byyearday && rule.options.byyearday.length > 0
                    ? rule.options.byyearday
                    : [getDayOfYear(date)],
            finishType: RecurrenceHelper.getFinishType(rule),
            count: rule.options.count ?? 1,
            until: rule.options.until,
        });

        this.recurrenceText = RecurrenceHelper.parseRecurrenceMessage(
            rule.toString(),
            this.data.stampStartDate ?? false
        );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    get customFreq() {
        return this.form.get("customFreq").value;
    }

    get allowByHourDefinition() {
        return this.form.get("allowByHourDefinition").value;
    }

    get byHourArray(): FormArray {
        return this.form.get("byhour") as FormArray;
    }

    get hasMinLengthError() {
        let errors = this.byHourArray.errors;
        return errors && (errors?.notDistinct || errors?.minlength?.requiredLength > errors?.minlength?.actualLength);
    }

    addHour() {
        this.byHourArray.push(new FormControl(8, Validators.required));
    }

    removeByHour(index: number) {
        this.byHourArray.removeAt(index);
    }

    getByHourControlByIndex(i) {
        return this.byHourArray.controls[i] as FormControl;
    }

    countState(enabled: boolean) {
        if (enabled) {
            this.form.get("count").enable();
        } else {
            this.form.get("count").disable();
        }
    }

    untilState(enabled: boolean) {
        if (enabled) {
            this.form.get("until").enable();
        } else {
            this.form.get("until").disable();
        }
    }

    endDateState(enabled: boolean) {
        if (enabled) {
            this.form.get("endInterval").enable();
            this.form.get("endFreq").enable();
        } else {
            this.form.get("endInterval").disable();
            this.form.get("endFreq").disable();
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    save() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        let rule = this.parseDefinition();
        let recurrence = rule.toString();
        this.dialogRef.close(recurrence);
    }

    parseDefinition() {
        let definition: any = this.form.value;

        let until: Date = null;
        let frequency = (definition.freq as Frequency) ?? Frequency.WEEKLY;
        let interval = definition.interval;
        if (definition.finishType === "until") {
            until = definition.until?.toISOString();
        }
        if (definition.finishType === "endDate") {
            let dtstart = definition.dtstart ?? new Date();
            let start = definition.start ?? `${dtstart.getHours()}:${dtstart.getMinutes()}`;
            let endInterval = definition.endInterval;
            let endFreq = definition.endFreq as Frequency;
            dtstart.setHours(Number(start.split(":")[0]));
            dtstart.setMinutes(Number(start.split(":")[1]));
            until = this.countOccurrences(dtstart, interval, frequency, endInterval, endFreq);
        }

        let options = {
            wkst: RRule.SU,
            freq: frequency,
            interval: interval,
            byweekday: frequency === Frequency.WEEKLY ? definition.byweekday : null,
            byhour: definition.byhour?.length > 0 ? definition.byhour : null,
            bymonthday: frequency === Frequency.MONTHLY ? definition.bymonthday : null,
            byyearday: frequency === Frequency.YEARLY ? definition.byyearday : null,
            count: definition.count,
            until: until,
            bysecond: [0],
        };

        if (definition.dtstart) {
            let startTimeComponents = definition.start.split(":");
            set(
                options,
                "dtstart",
                setHours(setMinutes(definition.dtstart, startTimeComponents[1]), startTimeComponents[0])
            );
        }

        return new RRule(options);
    }

    countOccurrences(start: Date, interval, freq: Frequency, endInterval: number, endFreq: Frequency): Date {
        let end = new Date(start);
        let timeDifference: number;
        let numberOfOccurrences: number;
        let until: Date;

        switch (endFreq) {
            case Frequency.YEARLY:
                end = addYears(end, endInterval);
                timeDifference = end.getTime() - start.getTime();
                break;
            case Frequency.MONTHLY:
                end = addMonths(end, endInterval);
                timeDifference = end.getTime() - start.getTime();
                break;
            case Frequency.WEEKLY:
                end = addDays(end, endInterval * 7);
                timeDifference = end.getTime() - start.getTime();
                break;
            case Frequency.DAILY:
                end = addDays(end, endInterval);
                timeDifference = end.getTime() - start.getTime();
                break;
            case Frequency.HOURLY:
                end = addHours(end, endInterval);
                timeDifference = end.getTime() - start.getTime();
                break;
            case Frequency.MINUTELY:
                end = addMinutes(end, endInterval);
                timeDifference = end.getTime() - start.getTime();
                break;
        }

        switch (freq) {
            case Frequency.YEARLY:
                numberOfOccurrences = Math.floor(timeDifference / MILLISECONDS_IN_A_YEAR / interval);
                until = addYears(start, numberOfOccurrences * interval);
                break;
            case Frequency.MONTHLY:
                numberOfOccurrences = Math.floor(timeDifference / MILLISECONDS_IN_A_MONTH / interval);
                until = addMonths(start, numberOfOccurrences * interval);
                break;
            case Frequency.WEEKLY:
                numberOfOccurrences = Math.floor(timeDifference / MILLISECONDS_IN_A_WEEK / interval);
                until = addDays(start, numberOfOccurrences * interval * 7);
                break;
            case Frequency.DAILY:
                numberOfOccurrences = Math.floor(timeDifference / MILLISECONDS_IN_A_DAY / interval);
                until = addDays(start, numberOfOccurrences * interval);
                break;
            case Frequency.HOURLY:
                numberOfOccurrences = Math.floor(timeDifference / MILLISECONDS_IN_AN_HOUR / interval);
                until = addHours(start, numberOfOccurrences * interval);
                break;
            case Frequency.MINUTELY:
                numberOfOccurrences = Math.floor(timeDifference / MILLISECONDS_IN_A_MINUTE / interval);
                until = addMinutes(start, numberOfOccurrences * interval);
                break;
        }

        return until;
    }

    distinctValuesValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formArray = control as any;
            const values = formArray.controls.map((ctrl: AbstractControl) => ctrl.value);

            const uniqueValues = new Set(values);

            return uniqueValues.size === values.length ? null : { notDistinct: true };
        };
    }

    afterStartDateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const startDate = this.form.get("dtstart").value;
            const endDate = control.value;
            if (!endDate) {
                return null;
            }
            return endDate >= startDate ? null : { afterStartDate: true };
        };
    }
}
