<ng-container *transloco="let root">
    <ng-container *transloco="let t; read: 'health.engage.reminder-pool.edit-folder'">
        <ui-dialog-base [allowCancelTouchingOutside]="false" [title]="t('title')" [classes]="['sm:w-40vw']">
            <div *ngIf="isUpdating$ | async" class="absolute inset-x-0 top-0">
                <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
            </div>

            <div class="p-6 overflow-y-auto" fuseScrollbar>
                <form [formGroup]="form" class="w-full flex-auto">
                    <div class="grid sm:grid-cols-4 gap-6 w-full">
                        <mat-form-field class="fuse-mat-no-subscript col-span-4">
                            <input
                                cdkFocusInitial
                                maxlength="256"
                                matInput
                                formControlName="name"
                                [placeholder]="t('field-name-placeholder')"
                            />
                        </mat-form-field>

                        <mat-checkbox
                            formControlName="isPublic"
                            [matTooltip]="t('field-public-tooltip')"
                            [matTooltipPosition]="'right'"
                        >
                            {{ t("field-public-title") }}
                        </mat-checkbox>
                    </div>
                </form>
            </div>

            <dialog-actions class="flex flex-row items-center space-x-4 justify-end">
                <button mat-stroked-button mat-dialog-close>
                    {{ root("actions.cancel") }}
                </button>
                <button
                    mat-flat-button
                    [disabled]="!form.valid || (isUpdating$ | async)"
                    (click)="save()"
                    color="primary"
                >
                    {{ root("actions.save") }}
                </button>
            </dialog-actions>
        </ui-dialog-base>
    </ng-container>
</ng-container>
