import { FuseNavigation } from "@fuse/types";

export const NavigationItemAppManagement: FuseNavigation = {
    id: "e-commerce",
    title: "Meu app",
    type: "collapsable",
    icon: "mobile",
    children: [],
};

export const NavigationAppManagementSettings: FuseNavigation = {
    id: "wizard-edit",
    title: "Design",
    type: "item",
    url: "/app/edit",
};

export const NavigationAppManagementModules: FuseNavigation = {
    id: "wizard-modules",
    title: "Módulos",
    type: "item",
    url: "/app/features",
    exactMatch: true,
};

export const NavigationDashboardItem: FuseNavigation = {
    id: "dashboard",
    title: "Painel",
    type: "item",
    url: "/dashboard",
    icon: "dashboard",
};

export const NavigationItemScheduleCalendar: FuseNavigation = {
    id: "calendar",
    title: "Agenda",
    type: "item",
    url: "/calendar",
    icon: "calendar",
    exactMatch: true,
};

export const NavigationItemHealthPatients: FuseNavigation = {
    id: "health.patients",
    title: "Pacientes",
    type: "item",
    url: "/patient",
    icon: "patients",
    exactMatch: false,
};

export const NavigationItemHealthDoctors: FuseNavigation = {
    id: "health.doctor",
    title: "Profissionais saúde",
    type: "item",
    url: "/professionals",
};

export const NavigationItemSystemEmployees: FuseNavigation = {
    id: "system.employees",
    title: "Funcionários",
    type: "item",
    url: "/employee",
    anchor: true,
};

export const NavigationItemProfile: FuseNavigation = {
    id: "system.profile",
    title: "Meu Perfil",
    type: "item",
    url: "/account/profile",
    anchor: true,
};

export const NavigationItemMarketingManagement: FuseNavigation = {
    id: "e-commerce",
    title: "Comunicação",
    type: "collapsable",
    icon: "mkt",
    children: [],
};

export const NavigationMarketingPushNotification: FuseNavigation = {
    id: "send_notification",
    title: "Nova notificação push",
    type: "item",
    url: "/marketing/notification/create",
    exactMatch: true,
};

export const NavigationMarketingPushNotificationHistory: FuseNavigation = {
    id: "notifications",
    title: "Histórico",
    type: "item",
    url: "/marketing/notification",
    exactMatch: true,
};

export const NavigationItemSystemIntegrations: FuseNavigation = {
    id: "system.integration",
    title: "Integrações",
    type: "item",
    url: "/integration",
};

export const NavigationConfigHealthProcedures: FuseNavigation = {
    type: "item",
    id: "medical-procedures",
    title: "Procedimentos",
    url: "settings/care/procedures",
    anchor: true,
};

export const NavigationConfigHealthInsurance: FuseNavigation = {
    id: "health-insurance-companies",
    title: "Convênios e planos",
    type: "item",
    url: "/settings/care/insurance",
};

export const NavigationConfigHealthCareUnits: FuseNavigation = {
    id: "care-units",
    title: "Unidades",
    type: "item",
    url: "/settings/care/units",
};

export const NavigationItemGlobalStats: FuseNavigation = {
    id: "system.stats",
    title: "Dashboard",
    type: "item",
    url: "/stats",
    icon: "dashboard",
};

export const NavigationItemTenants: FuseNavigation = {
    id: "system.tenants",
    title: "Clientes",
    type: "item",
    url: "/tenants",
    icon: "settings",
};

export const NavigationItemUrlGenerator: FuseNavigation = {
    id: "system.subscription.url-generator",
    title: "Gerar assinatura",
    type: "item",
    url: "/url-generator",
    icon: "settings",
};

export const NavigationConfigProfessionalProcedures: FuseNavigation = {
    type: "item",
    id: "professional-medical-procedures",
    title: "Meus Procedimentos",
    url: "care/procedures",
    icon: "doctor",
};

export const NavigationItemSettings: FuseNavigation = {
    id: "settings",
    title: "Configurações",
    type: "collapsable",
    icon: "settings",
    children: [],
    anchor: true,
};

export const NavigationConfigInfo: FuseNavigation = {
    type: "item",
    id: "user-info",
    title: "Perfil",
    url: "user/profile",
};

export const NavigationConfigProfessionalInfo: FuseNavigation = {
    type: "item",
    id: "professional-info",
    title: "Dados profissionais",
    url: "user/profile",
};

export const NavigationConfigDigitalSignature: FuseNavigation = {
    type: "item",
    id: "user-info-digital-sign",
    title: "Assinatura",
    url: "user/signature",
};

export const NavigationConfigGeneralInfo: FuseNavigation = {
    type: "item",
    id: "tenant-info",
    title: "Minha clínica",
    url: "settings/info",
};

export const NavigationItemClinicSettings: FuseNavigation = {
    id: "clinic-settings",
    title: "Cadastros",
    type: "collapsable",
    children: [],
    anchor: true,
};

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "Painel",
        type: "group",
        icon: "apps",
        children: [
            NavigationItemAppManagement,
            NavigationItemScheduleCalendar,
            NavigationItemHealthPatients,
            NavigationItemHealthDoctors,
            NavigationItemSystemEmployees,
            NavigationItemMarketingManagement,
            NavigationItemProfile,
        ],
    },
];

export const NavigationConfigSchedule: FuseNavigation = {
    type: "item",
    id: "calendar-settings",
    title: "Agenda",
    url: "calendar/settings",
    exactMatch: true,
};

export const NavigationConfigMedicalRecord: FuseNavigation = {
    type: "item",
    id: "medicalRecord-settings",
    title: "Prontuário",
    url: "medical-record/settings",
    exactMatch: false,
};

export const NavigationConfigHeathEngage: FuseNavigation = {
    type: "item",
    title: "Health Engage",
    url: "health-engage",
    id: "health-engage",
    exactMatch: false,
};

export const NavigationConfigChildGrowthCurve: FuseNavigation = {
    type: "item",
    id: "childGrowthCurve-settings",
    title: "Módulos",
    url: "medical-modules/settings",
    exactMatch: true,
};

export const NavigationItemPay: FuseNavigation = {
    id: "vSaude.Pay",
    title: "vPay",
    type: "item",
    url: "/pay",
    icon: "vpay",
};

export const NavigationConfigDocumentTemplates: FuseNavigation = {
    id: "document-emplates",
    title: "Documentos",
    type: "item",
    url: "/document/template",
    exactMatch: false,
};

export const NavigationConfigPatient: FuseNavigation = {
    id: "patient/settings",
    title: "Dados do paciente",
    type: "item",
    url: "/settings/patient",
    exactMatch: true,
};

export const NavigationReport: FuseNavigation = {
    id: "report",
    title: "Relatórios",
    type: "collapsable",
    icon: "report",
    children: [],
};

export const NavigationReportAppointments: FuseNavigation = {
    id: "appointments-report",
    title: "Agendamentos",
    type: "item",
    url: "report/appointment",
    exactMatch: true,
};

export const NavigationReportAttendances: FuseNavigation = {
    id: "attendance-report",
    title: "Atendimentos",
    type: "item",
    url: "report/attendance",
    exactMatch: true,
};

export const NavigationFinancialConfig: FuseNavigation = {
    id: "financial_navigation",
    title: "Base Financeira",
    type: "item",
    url: "financial/navigation",
    exactMatch: true,
};

export const NavigationFinancialRevenues: FuseNavigation = {
    id: "financial_revenues",
    title: "Receitas",
    type: "item",
    url: "financial/revenue",
    exactMatch: true,
};

export const NavigationFinancialExpenses: FuseNavigation = {
    id: "financial_expenses",
    title: "Despesas",
    type: "item",
    url: "financial/expenses",
    exactMatch: true,
};

export const NavigationFinancialCashFlow: FuseNavigation = {
    id: "financial_report_cash_flow",
    title: "Fluxo de caixa",
    type: "item",
    url: "financial/report/cash-flow",
    exactMatch: true,
};

export const NavigationFinancial: FuseNavigation = {
    id: "financial",
    title: "Financeiro",
    type: "collapsable",
    icon: "financial",
    badge: {
        title: "Beta",
    },
    children: [],
};
