import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as ApiServiceProxies from './service-proxies';
import { AbpHttpInterceptor } from 'abp-ng2-module';
import { CareUnitHttpInterceptor } from '@app/wapps/health-care/care-unit-http-interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.HealthTenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserAvatarServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.FilesServiceServiceProxy,
        ApiServiceProxies.WizardServiceProxy,
        ApiServiceProxies.FeatureServiceProxy,
        ApiServiceProxies.PatientServiceServiceProxy,
        ApiServiceProxies.PrescriptionServiceServiceProxy,
        ApiServiceProxies.ExaminationServiceServiceProxy,
        ApiServiceProxies.ExaminationModelServiceServiceProxy,
        ApiServiceProxies.PostalCodeServiceServiceProxy,
        ApiServiceProxies.ThemeServiceServiceProxy,
        ApiServiceProxies.IconServiceServiceProxy,
        ApiServiceProxies.MedicalProfessionServiceServiceProxy,
        ApiServiceProxies.MedicalAppointmentServiceServiceProxy,
        ApiServiceProxies.MedicalAgreementServiceServiceProxy,
        ApiServiceProxies.InvitationServiceServiceProxy,
        ApiServiceProxies.RemoteNotificationsServiceServiceProxy,
        ApiServiceProxies.ContactServiceProxy,
        ApiServiceProxies.PublishServiceServiceProxy,
        ApiServiceProxies.SignatureServiceServiceProxy,
        ApiServiceProxies.CertificateServiceServiceProxy,
        ApiServiceProxies.GoogleCalendarServiceServiceProxy,
        ApiServiceProxies.GoogleCalendarConnectServiceProxy,
        ApiServiceProxies.EditionServiceServiceProxy,
        ApiServiceProxies.MedicationServiceServiceProxy,
        ApiServiceProxies.DocumentsServiceServiceProxy,
        ApiServiceProxies.DoctorServiceServiceProxy,
        ApiServiceProxies.ChildGrowthCurveConfigurationServiceServiceProxy,
        ApiServiceProxies.ChildGrowthServiceServiceProxy,
        ApiServiceProxies.ScheduleServiceServiceProxy,
        ApiServiceProxies.TransactionServiceServiceProxy,
        ApiServiceProxies.ScheduleConfigurationServiceServiceProxy,
        ApiServiceProxies.MedicalRecordLegacyFormServiceServiceProxy,
        ApiServiceProxies.MedicalRecordEntryServiceServiceProxy,
        ApiServiceProxies.PatientRegistrationConfigurationServiceServiceProxy,
        ApiServiceProxies.MedicalRecordConfigurationServiceServiceProxy,
        ApiServiceProxies.SellerServiceServiceProxy,
        ApiServiceProxies.MedicalRecordFormDefinitionServiceServiceProxy,
        ApiServiceProxies.MedicalRecordFormServiceServiceProxy,
        ApiServiceProxies.MedicalProcedureServiceServiceProxy,
        ApiServiceProxies.InsuranceCompanyServiceServiceProxy,
        ApiServiceProxies.HealthProfessionalServiceServiceProxy,
        ApiServiceProxies.HealthProfessionalMedicalProcedureServiceServiceProxy,
        ApiServiceProxies.HealthCareUnitServiceServiceProxy,
        ApiServiceProxies.InsurancePlanServiceServiceProxy,
        ApiServiceProxies.InsuranceCompanyServiceServiceProxy,
        ApiServiceProxies.PregnancyFormDefinitionServiceServiceProxy,
        ApiServiceProxies.PregnancyCardServiceServiceProxy,
        ApiServiceProxies.DigitalSignatureServiceProxy,
        ApiServiceProxies.BirdIdServiceProxy,
        ApiServiceProxies.PreServiceProxy,
        ApiServiceProxies.AppointmentServiceProxy,
        ApiServiceProxies.SiteServiceServiceProxy,
        ApiServiceProxies.TermsOfServiceServiceProxy,
        ApiServiceProxies.MedicationServiceProxy,
        ApiServiceProxies.HealthProfessionalServiceProxy,
        ApiServiceProxies.SupportServiceProxy,
        ApiServiceProxies.FolderTemplateServiceProxy,
        ApiServiceProxies.VidaasSignatureServiceProxy,
        ApiServiceProxies.HealthTenantServiceServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CareUnitHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
