import { Injectable } from "@angular/core";
import { AppSessionService } from "@shared/session/app-session.service";
import { combineLatest } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AppConsts } from "@shared/AppConsts";
import { productFruits } from "product-fruits";
import { HealthFeatures } from "@app/wapps/health/feature-names";

@Injectable({
    providedIn: "root",
})
export class SupportTourService {
    hideRequested: boolean;

    constructor(private _usersService: AppSessionService) {}

    init() {
        let sessionChanged$ = this._usersService.onSessionChanged;
        let tenantInfoChanged$ = this._usersService.onTenantInfoChanged;

        combineLatest([sessionChanged$, tenantInfoChanged$]).subscribe((value) => {
            let user = value[0];
            let tenant = value[1];
            if (user && tenant) {
                this.startLogged(user, tenant);
            }
        });
    }

    startLogged(user, tenant) {
        if (!AppConsts.TourEnabled || this._usersService.isImpersonating()) {
            return;
        }

        this.startProductFruits(user, tenant);
    }

    startProductFruits(user, tenant) {
        productFruits.init(
            "qJ1AqggqxjfisP0f",
            "pt",
            {
                username: user.userName,
                email: user.userName,
                firstname: user.name,
                lastname: user.surname,
                props: {
                    roles: user.roleNames,
                    healthEngageEnabled: abp.features.isEnabled(HealthFeatures.Engage),
                },
            },
            {
                disableLocationChangeDetection: false,
            }
        );

        productFruits.safeExec(($pf) => {
            console.log($pf);
        });
    }
}
