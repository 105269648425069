import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/internal/operators";
import { NotificationService, NotificationType } from "app/wapps/notification/notification.service";
import { UserNotification } from "shared/service-proxies/service-proxies";
import { filter } from "rxjs/operators";
import { HealthFeatures } from "../health/feature-names";

@Injectable({
    providedIn: "root",
})
export class PrescriptionConfigService implements OnDestroy {
    _unsubscribeAll = new Subject();

    _prescriptionCommentsOrder: boolean = abp.setting.getBoolean("Health.Documents.Prescription.CommentsOrder");

    prescriptionCommentsOrder$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        this._prescriptionCommentsOrder
    );

    prescriptionDraftFeatureEnabled: boolean = abp.setting.getBoolean(HealthFeatures.PrescriptionDraft);

    constructor(private _notificationService: NotificationService) {
        this._notificationService.onNotificationArrived
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter(
                    (userNotification: UserNotification) =>
                        !!userNotification &&
                        userNotification.notification.data.type === NotificationType.PrescriptionUpdateCommentsOrder
                )
            )
            .subscribe((userNotification: UserNotification) => {
                let order = userNotification.notification.data.properties.prescriptionCommentsOrder;
                this.updateSetting(order);
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    updateSetting(order: boolean) {
        this._prescriptionCommentsOrder = order;
        this.prescriptionCommentsOrder$.next(this._prescriptionCommentsOrder);
    }
}
