import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { SafeHtmlTextPipe } from "./SafeHtmlText.pipe";
import { SafeUrlPipe } from "./safeUrl.pipe";
import { FormatSizeStrPipe } from './formatSize.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        SafeHtmlTextPipe,
        SafeUrlPipe,
        FormatSizeStrPipe,
    ],
    imports: [],
    exports: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        SafeHtmlTextPipe,
        SafeUrlPipe,
        FormatSizeStrPipe,
    ]
})
export class FusePipesModule {
}
