import { Injectable, Injector } from "@angular/core";
import { PlatformLocation } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import moment from "moment-timezone";
import "moment-timezone";
import * as _ from "lodash";
import { environment } from "./environments/environment";
import { AppConsts } from "@shared/AppConsts";
import { AppSessionService } from "@shared/session/app-session.service";

@Injectable({
    providedIn: "root",
})
export class AppInitializer {
    constructor(
        private _injector: Injector,
        private _platformLocation: PlatformLocation,
        private _httpClient: HttpClient
    ) {}

    init(): () => Promise<boolean> {
        return () => {
            return new Promise<boolean>((resolve, reject) => {
                AppConsts.appBaseUrl = this.getBaseHref();
                const appBaseUrl = this.getDocumentOrigin() + AppConsts.appBaseUrl;
                this.getApplicationConfig(appBaseUrl, () => {
                    this.getUserConfiguration(() => {
                        abp.event.trigger("abp.dynamicScriptsInitialized");
                        // do not use constructor injection for AppSessionService
                        const appSessionService = this._injector.get(AppSessionService);
                        appSessionService.init().then(
                            (result) => {
                                abp.ui.clearBusy();
                                if (this.shouldLoadLocale()) {
                                    const angularLocale = this.convertAbpLocaleToAngularLocale(
                                        abp.localization.currentLanguage.name
                                    );
                                    resolve(result);
                                } else {
                                    resolve(result);
                                }
                            },
                            (err) => {
                                abp.ui.clearBusy();
                                reject(err);
                            }
                        );
                    });
                });
            });
        };
    }

    private getBaseHref(): string {
        const baseUrl = this._platformLocation.getBaseHrefFromDOM();
        if (baseUrl) {
            return baseUrl;
        }

        return "/";
    }

    private getDocumentOrigin(): string {
        if (!document.location.origin) {
            const port = document.location.port ? ":" + document.location.port : "";
            return document.location.protocol + "//" + document.location.hostname + port;
        }

        return document.location.origin;
    }

    private shouldLoadLocale(): boolean {
        return abp.localization.currentLanguage.name && abp.localization.currentLanguage.name !== "en-US";
    }

    private convertAbpLocaleToAngularLocale(locale: string): string {
        if (!AppConsts.localeMappings) {
            return locale;
        }

        const localeMapings = _.filter(AppConsts.localeMappings, { from: locale });
        if (localeMapings && localeMapings.length) {
            return localeMapings[0]["to"];
        }

        return locale;
    }

    private getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
        if (currentProviderName === "unspecifiedClockProvider") {
            return abp.timing.unspecifiedClockProvider;
        }

        if (currentProviderName === "utcClockProvider") {
            return abp.timing.utcClockProvider;
        }

        return abp.timing.localClockProvider;
    }

    private getUserConfiguration(callback: () => void): void {
        let sessionData: any = JSON.parse(localStorage.getItem("session_data")) || {};
        let sessionKey = "session=";
        let i = document.location.href.indexOf(sessionKey);
        if (i > 0) {
            let accessToken = document.location.href.substring(i + sessionKey.length).split("&")[0];
            console.log(accessToken);
            sessionData = { accessToken: accessToken };
        }

        if (sessionData.accessToken) {
            let expireDate: Date = null;
            try {
                expireDate = new Date(sessionData.expireDate);
            } catch (error) {}
            abp.auth.setToken(sessionData.accessToken, expireDate);
            if (sessionData.encryptedAccessToken) {
                abp.utils.setCookieValue(
                    AppConsts.authorization.encryptedAuthTokenName,
                    sessionData.encryptedAccessToken,
                    expireDate,
                    abp.appPath
                );
            }
        }

        const cookieLangValue = abp.utils.getCookieValue("Abp.Localization.CultureName");
        const token = abp.auth.getToken();

        const requestHeaders = {
            "Abp.TenantId": `${abp.multiTenancy.getTenantIdCookie()}`,
            ".AspNetCore.Culture": `c=${cookieLangValue}|uic=${cookieLangValue}`,
        };

        if (token) {
            requestHeaders["Authorization"] = `Bearer ${token}`;
        }

        this._httpClient
            .get<any>(`${AppConsts.remoteServiceBaseUrl}/AbpUserConfiguration/GetAll`, {
                headers: requestHeaders,
            })
            .subscribe((response) => {
                const result = response.result;

                _.merge(abp, result);

                abp.clock.provider = this.getCurrentClockProvider(result.clock.provider);

                moment.locale(abp.localization.currentLanguage.name);

                if (abp.clock.provider.supportsMultipleTimezone) {
                    moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
                }

                callback();
            });
    }

    private getApplicationConfig(appRootUrl: string, callback: () => void) {
        AppConsts.appBaseUrl = location.origin;
        let apiUrl = environment.api_url;
        if (apiUrl?.length === 0) {
            apiUrl = location.origin;
        }

        AppConsts.remoteServiceBaseUrl = apiUrl;
        this._httpClient
            .get<any>(`${apiUrl}/api/services/Config/GetConfig`, {
                headers: {
                    "Abp.TenantId": `${abp.multiTenancy.getTenantIdCookie()}`,
                },
            })
            .subscribe((response) => {
                let result = response.result;
                AppConsts.ionicPreviewServiceBaseUrl = result.ionicPreviewServiceBaseUrl;
                AppConsts.MapsApiKey = result.mapsApiKey;
                AppConsts.GtmId = result.gtmId;
                AppConsts.FacebookPixelId = result.facebookPixelId;
                AppConsts.MeetDomain = result.meetDomain;
                AppConsts.VindiUrl = result.vindiUrl;
                AppConsts.VindiToken = result.vindiToken;
                AppConsts.ChatEnabled = result.chatEnabled;
                AppConsts.GoogleCalendarEnabled = result.googleCalendarEnabled;
                AppConsts.SupportChat = result.supportChat;
                AppConsts.vMeetEnabled = result.vmeetEnabled;
                AppConsts.WootricEnabled = result.wootricEnabled;
                AppConsts.TourEnabled = result.tourEnabled;
                callback();
            });
    }
}
